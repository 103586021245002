<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title mb-0">MUSIC ALBUMS</p>
    </div>
    <div class="dialog-content">
      <b-container class="mt-3" fluid>
        <p class="medium mb-3">
          <span class="mr-2"><b>Music Category:</b></span>
          {{ data.music_category_title }}
        </p>

        <multiselect label="album_name"
                    :options="musicAlbums"
                    :multiple="true"
                    v-model="categAlbums"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    track-by="album_name"
                    placeholder="Select Album"
                    @select="selectAlbum"
                    @remove="removeAlbum">
          
          <template slot="selection" slot-scope="{ values, search, isOpen }">
            <span class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen">
              {{ values.length }} albums selected
            </span>
          </template>
          
          <template slot="option" slot-scope="props">
            <img class="option__image"
                :src="props.option.album_thumbnail"
                :alt="props.option.album_name" />
            <span class="option__desc">
              <span class="option__title" style="width: 10rem !important;">
                {{ props.option.album_name | truncate(30) }}
              </span>
            </span>
          </template>
        </multiselect>

        <p class="medium mt-3 mb-2">
          <b v-if="categAlbums.length > 1">Albums</b>
          <b v-else>Album</b>
        </p>
        <div class="selected-albums" style="height: 40vh;">
          <ul class="list-group" v-if="categAlbums.length > 0">
            <li class="list-group-item d-flex justify-content-between
                  align-items-center"
                v-for="(album, index) in categAlbums"
                :key="index">
              <div>
                <img class="album-thumbnail mr-1"
                      :src="album.album_thumbnail"
                      alt="album-thumbnail" />
                {{ album.album_name | truncate(60) }}
              </div>
              <i class="fas fa-times text-right remove-icon"
                title="Remove Album"
                @click="removeAlbum(album)"></i>
            </li>
          </ul>

          <p class="medium text-center" v-else>No Album selected</p>
        </div>

        <!-- Buttons -->
        <div class="d-flex justify-content-end">
          <b-button class="text-center mt-3 ml-1"
                    variant="outline-danger"
                    @click="$emit('close')">
            Close
          </b-button>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
  const Multiselect = () => import('vue-multiselect');

  import _ from 'lodash';
  import { mapActions, mapGetters } from 'vuex';
  import FilterMixin from '@/mixins/FilterMixin';

  export default {
    name   : 'MusicAlbums',
    mixins : [
      FilterMixin,
    ],
    props : {
      data : Object,
    },
    data() {
      return {
        musicAlbums : [],
        categAlbums : [],
      }
    },
    computed : {
      ...mapGetters({
        albums : 'media/albums',
      }),
    },
    watch : {
      albums(value) {
        if (value.length > 0) {
          const alb = [];
          for (let i in value) {
            alb.push(value[i].item);
            i++;
          }
          this.musicAlbums = alb;
        }
      },
    },
    components : {
      Multiselect,
    },
    methods : {

      ...mapActions({
        'getAlbums' : 'media/getAlbums',
      }),

      /**
       * Get Category Albums
       */
      getCategoryAlbums() {
        this.$http.get('api/music/categoryalbum', {
          params : {
            mCategoryId : this.data.music_category_id,
            page        : 1,
            perPage     : 1000,
          },
        }).then(response => {
          this.categAlbums = response.data.result.data;
        });
      },

      /**
       * Add Album
       * @param album
       */
      selectAlbum(album) {
        this.$http.post('api/music/categoryalbum', {
          mCategoryId : this.data.music_category_id,
          albumId     : album.mobile_album_id,
        }).then(() => {
          this.getCategoryAlbums();
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : `Added <b>${album.album_name}</b> album`,
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Remove Album
       * @param album
       */
      removeAlbum(album) {
        this.$http.delete('api/music/categoryalbum', {
          data : {
            mCategoryId : this.data.music_category_id,
            albumId     : album.mobile_album_id,
          },
        }).then(() => {
          this.categAlbums = _.remove(this.categAlbums, function(item) {
            return item.mobile_album_id !== album.mobile_album_id;
          });

          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Removed ' + album.album_name + ' album',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },
    },
    mounted() {
      this.getAlbums();
      this.getCategoryAlbums();
    },
  }
</script>

<style lang="scss" scoped>
  @import
    "../../../../assets/scss/components/admin/category/music/music-albums";
</style>